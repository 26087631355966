import React from "react"
import Layout from "../components/layout"
// import "react-responsive-carousel/lib/styles/carousel.min.css"
import TechnicalData from "../components/product_tech_data"
import ProductDetail from "../components/product_detail"
import fogging_machine from "../../public/static/images/products/fogging_machine.jpg"

function FoggingMachine() {

  const prodDetail = {
    "name": "FOGGING MACHINE",
    "detail": {
      "about" : ["Leading Trader and Supplier of Fogging Machine such as Handy Fogging Machine, Vehicle Mounted Fogging Machine and Portable Fogging Machine", "Used for sanitation and epidemic prevention in House, Garden, Hospital, Restaurant, Hotel and other public places."],
      "advantages": [
        "Work wide area with short times and you can mount the machine on the chassis",
        "Control the machine with the remote controller in the driver’s cabin",
        "Save the cost of labour because only one person can operate the machine",
        "This machine covers 6600 square meters with one time spray with 100 liters chemical"
      ]
    },
    "tech_data": {
      "column1": [
        "Fuel Consumption Petrol",
        "Diesel Consumption",
        "Application Rate",
        "Weight",
        "Mounted",
        "Operation Power"
      ],
      "column2": [
        "3.3 Lit. /hour",
        "85 Lit./ Hour",
        "25 To 40 Lit. / Hour",
        "45 Kg (empty)",
        "3 Wheeler / Tata Ace / Tractor Trolley",
        "Chassis / Tractor Battery"
      ]
    }
  }

  return (
    <Layout className="semi-automatic">
      <ProductDetail name={prodDetail.name} detail={prodDetail.detail} image={fogging_machine} />

      {prodDetail.tech_data !== undefined ? <TechnicalData techData={prodDetail.tech_data} /> : <></>}
    </Layout>
  )
}

export default FoggingMachine
